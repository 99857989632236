import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const FooterOne = ({ openChat }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleSupportClick = () => {
        if (openChat) {
            openChat(); // Invoke the function to open the chat
        }
    };

    return (
        <>
            <section className="footer footer-3 pt-150" style={{ background: '#CADFDF' }}>
                <div className="">
                    <div className="container">
                        <div className="row row-width" >
                            <div className="col-xl-3 col-sm-8 me-auto" >
                                <div className="widget footer-widget mb-5 mb-lg-0" >
                                    <Link to='/' className="footer-logo">
                                        <img src="assets/images/logo.png" alt="" className="img-fluid" />
                                        <h2>BUGnBULL</h2>
                                    </Link>

                                    <p style={{ color: 'black' }} className="mt-4">Conquer The Wallstreet, Rule The Web.</p>

                                    <div className="footer-socials mt-5">
                                        <span className="me-2" style={{ color: 'black' }}>Connect :</span>
                                        <li className="list-inline-item"><Link to="https://www.facebook.com/share/A5VjWW9rTmcXYYey/?mibextid=qi2Omg" target='blank'><i className="fab fa-facebook-f"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://x.com/bugndbull"><i className="fab fa-twitter"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.instagram.com/invites/contact/?igsh=1c6g5qh2xuzr8&utm_content=utkdnsl" target='blank'><i className="fab fa-instagram"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://youtube.com/@bugnbull?si=ml0hbVvO0UhK00bN" target='blank'><i className="fab fa-youtube"></i></Link></li>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-sm-6">
                                <div className="footer-widget mb-5 mb-lg-0">
                                    <h5 className="widget-title">Explore</h5>
                                    <ul className="list-unstyled footer-links">
                                        <li><Link to="about">About us</Link></li>
                                        {/* <li><Link to="contact">Contact us</Link></li> */}
                                        <li><Link to="#">Blogs</Link></li>
                                        <li><Link onClick={handleSupportClick}>Support</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-sm-3 footer-margin-down" >
                                <div className="footer-widget mb-5 mb-lg-0">
                                    <h5 className="widget-title ">Programs</h5>
                                    <ul className="list-unstyled footer-links">
                                        <li><Link to="/cyber-security">Cyber Security</Link></li>
                                        <li><Link to="/stock-market">Stock Market</Link></li>
                                        <li><Link to="/web-development">Web Development</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-sm-3">
                                <div className="footer-widget mb-5 mb-lg-0">
                                    <h5 className="widget-title">Links</h5>
                                    <ul className="list-unstyled footer-links">
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/terms-of-service">Terms Of Service</Link></li>
                                        <li><Link to="/return-policy">Return Policy</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-2 col-sm-3">
                                <div className="footer-widget mb-5 mb-lg-0">
                                    <h5 className="widget-title">Address</h5>
                                    <ul className="list-unstyled footer-links">
                                        <li><Link to="tel:+918560958895">+918560958895</Link></li>
                                        <li><Link to="mailto:bugndbull@gmail.com">bugndbull@gmail.com</Link></li>
                                        <li><Link to="#">Jaipur, Rajasthan 302012</Link></li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="col-xl-2 col-sm-4">
                                <div className="footer-widget mb-5 mb-lg-0">
                                    <h5 className="widget-title">Helpful Links</h5>
                                    <ul className="list-unstyled footer-links">
                                        <li><Link to="/terms-of-service">Terms Of Service</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/contact">Join Us</Link></li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="col-xl-6 col-sm-12 col-lg-6">
                                <p className="mb-0 copyright text-sm-center footer-copyright" style={{ color: 'black' }}>© 2024 BUGnBULL All rights reserved </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="footer-btm">
                    <div className="container">
                        <div className="row align-items-center">

                            {windowWidth <= 768 ? <div>
                                <ul className="list-unstyled footer-links">
                                    <li><Link to="/terms-of-service">Terms Of Service</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/contact">Join Us</Link></li>
                                </ul>
                                <div className="col-xl-6 col-sm-12 col-lg-6">
                                    <p className="mb-0 copyright text-sm-center text-lg-start" style={{ color: 'black' }}>© 2024 BUGnBULL All rights reserved </p>
                                </div>
                            </div> : <div className='' >
                                <div className="col-xl-6 col-sm-12 col-lg-6">
                                    <div className="footer-btm-links text-start text-sm-center text-lg-end">
                                        <ul className="list-inline">
                                            <li className="list-inline-item"><Link to="/terms-of-service">Terms of Service</Link></li>
                                            <li className="list-inline-item"><Link to="/contact">Join Us</Link></li>
                                            <li className="list-inline-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-sm-12 col-lg-6">
                                    <p className="mb-0 copyright text-sm-center text-lg-start" style={{ color: 'black' }}>© 2024 BUGnBULL All rights reserved </p>
                                </div>

                            </div>
                            }

                        </div>
                    </div>
                </div> */}

            </section>
        </>
    )
}

export default FooterOne;