import React, { createContext, useState, useEffect } from 'react';

// Create Context
export const OrientationContext = createContext();

export const OrientationProvider = ({ children }) => {
    const [orientation, setOrientation] = useState('portrait'); // Default orientation

    const detectOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches) {
            setOrientation('portrait');
        } else {
            setOrientation('landscape');
        }
    };

    useEffect(() => {
        // Detect orientation on initial load
        detectOrientation();

        // Add event listeners for orientation changes
        const handleResizeOrOrientationChange = () => detectOrientation();
        window.addEventListener('resize', handleResizeOrOrientationChange);
        window.addEventListener('orientationchange', handleResizeOrOrientationChange);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener('resize', handleResizeOrOrientationChange);
            window.removeEventListener('orientationchange', handleResizeOrOrientationChange);
        };
    }, []);

    return (
        <OrientationContext.Provider value={orientation}>
            {children}
        </OrientationContext.Provider>
    );
};
