import React, { useRef } from 'react';

import HeaderOne from '../../common/header/HeaderOne';

import { Banner, Counter, CourseGrid01, CourseCat01, About, Features, Team, Cta } from '../layouts/home01/index';


const HomeOne = () => {
  const courseGridRef = useRef(null);
  const courseCatRef = useRef(null);

  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="home-page">
      <HeaderOne onCoursesClick={() => scrollToElement(courseCatRef)} />
      <Banner onTrendingCoursesClick={() => scrollToElement(courseGridRef)} />
      <Counter />
      <div ref={courseGridRef}>
        <CourseGrid01 />
      </div>
      <div ref={courseCatRef}>
        <CourseCat01 />
      </div>
      <About />
      <Features />
      <Team />
      <Cta />
    </div>
  )
}

export default HomeOne;