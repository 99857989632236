import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CourseCat01 extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         category: [
    //             {
    //                 id: 1,
    //                 title: 'Cyber Security',
    //                 img: 'assets/images/icon/icon8.png',
    //                 extraClassName: 'bg-1',
    //             },
    //             {
    //                 id: 2,
    //                 title: 'Stock Market',
    //                 img: 'assets/images/icon/icon7.png',
    //                 extraClassName: 'bg-2',
    //             },
    //             {
    //                 id: 3,
    //                 title: 'Web Development',
    //                 img: 'assets/images/icon/icon6.png',
    //                 extraClassName: 'bg-3',
    //             },
    //             // {
    //             //     id: 4,
    //             //     title: 'Web Development',
    //             //     img :  'assets/images/icon/icon4.png',
    //             //     extraClassName: 'bg-4',
    //             // },
    //             // {
    //             //     id: 5,
    //             //     title: 'Digital Marketing & SEO',
    //             //     img :  'assets/images/icon/icon6.png',
    //             //     extraClassName: 'bg-5',
    //             // },

    //         ]
    //     }
    // }

    render() {
        // Course category

        return (
            <section className="course-category-3 section-padding">
                <div className="container">
                    <div className="row row-width justify-content-center">
                        <div className="col-xl-8">
                            <div className="section-heading text-center">
                                <h2 className="font-lg">Design Your Future With Knowledge</h2>
                                <p>Turn your dreams into reality through learning.</p>
                            </div>
                        </div>
                    </div>

                    <section className="cards " style={{marginLeft:'-60px', width:'120%'}}>
                        <article className="card card--1">
                            <Link to='/cyber-security'>
                                <div className="card__img"></div>
                                <div className="card__img--hover"></div>
                                <div className="card__info">
                                    <h5 className="card__title">Cyber Security</h5>
                                    <span className="card__by">by Tanishk Sharma</span>
                                </div>
                            </Link>
                        </article>

                        <article className="card card--2">
                            <Link to='/stock-market'>
                                <div className="card__img"></div>
                                <div className="card__img--hover"></div>
                                <div className="card__info">
                                    <h5 className="card__title">Stock Market</h5>
                                    <span className="card__by">by Punit Jain</span>
                                </div>
                            </Link>
                        </article>

                        <article className="card card--3">
                            <Link to='/web-development'>
                                <div className="card__img"></div>
                                <div className="card__img--hover"></div>
                                <div className="card__info">
                                    <h5 className="card__title">Web Development</h5>
                                    <span className="card__by">by Ujjwal Natani</span>
                                </div>
                            </Link>
                        </article>
                    </section>

                </div>
            </section>

        );
    }
}
export default CourseCat01;